.training-steps {
  h2 {
    font-size: 4em;
    font-weight: 900;
    margin-top: 0;
    line-height: 1;

    @media screen and (max-width: $laptop-m) {
      font-size: 3em;
    }

    @media screen and (max-width: $tablet) {
      font-size: 2em;
      
      span {
        font-size: 1em !important;
        margin-left: 0 !important;
      }
      
      br {
        display: none;
      }
    }
  }
}

.steps {
  &:not(:last-child) {
    margin-bottom: 5em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 3em;
    }
  }
  
  .step {
    @media screen and (max-width: $laptop-m) {
      font-size: .8em;
    }
    
    .step__icon {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: 11em;
        height: 11em;
        border-radius: 50%;
        background: rgba(0, 174, 239, 0.25098);
      }

      img {
        position: relative;
        z-index: 1;
        height: 6em;
        @include object-fit(contain);
      }
    }

    &__pink {
      .step__icon:before {
        background: rgba(204, 2, 86, 0.25098);
      }

      .step__title {
        color: rgb(204, 2, 86);
      }
    }

    &__green {
      .step__icon:before {
        background: rgba(168, 207, 69, 0.301961);
      }

      .step__title {
        color: rgb(168, 207, 69);
      }
    }

    &__text {
      font-size: 1.75em;
      min-height: 5em;
      padding-top: 0.2em;
      position: relative;
      z-index: 1;
    }

    &__title {
      font-size: 1.5em;
      text-transform: uppercase;
      font-weight: 700;
      color: rgb(0, 174, 239);
      position: relative;
      z-index: 1;
    }
  }
}