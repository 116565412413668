.header {
  padding-top: 24/16+em;
  padding-bottom: 24/16+em;
  position: relative;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
  z-index: 3;
  background: #fff;

  @media screen and (max-width: $tablet) {
    padding-top: 1.8em;
    background: none;
    box-shadow: none;
  }

  .logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    a {
      display: block;
    }

    img {
      width: 96/16+em;
      margin-right: 20/16+em;

      @media screen and (max-width: $tablet) {
        width: 3em;
      }
    }

    &__text {
      font-size: 20/16+em;
      font-weight: bold;
      color: $dark-blue;

      @media screen and (max-width: $tablet) {
        display: none;
      }

      span {
        font-weight: 900;
      }

      .red {
        color: $red;
        text-transform: uppercase;
      }

      .blue {
        color: $blue;
      }
    }
  }

  .container {
    display: flex;
    //align-items: center;
    justify-content: space-between;
    align-items: flex-end;
  }

  .header-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: $laptop-s) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: $extra-light-blue;
      z-index: 99;
      box-shadow: $box-shadow;
      padding: 2rem 5rem;
      display: none;
      animation: menuFadeDown forwards .3s;
      -webkit-overflow-scrolling: touch;
      align-items: center;

      &.opened {
        display: flex;
      }

      @keyframes menuFadeDown {
        0% {
          transform: translateY(-10px);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }

    @media screen and (max-width: $tablet) {
      padding: 6rem 2rem 2rem;
      overflow-x: hidden;
      height: 100vh;

      &:before {
        content: '';
        width: 100%;
        height: 2px;
        background: #fff;
        margin-bottom: 1.875rem;
        display: block;
        flex-shrink: 0;
      }
    }

    &__top {
      margin-bottom: 25/16+em;
      display: flex;
      align-items: center;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
        margin-bottom: 30/16+em;
      }
      
      &>* {
        &:not(:last-child) {
          @media screen and (min-width: $tablet + 1) {
            margin-right: 25/16+em;
          }

          @media screen and (max-width: $tablet) {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .language {
    text-transform: uppercase;
    display: flex;
    align-items: center;

    @media screen and (max-width: $tablet) {
      margin: 1rem 0 0.2rem;
    }

    &>*:not(:last-child) {
      margin-right: 10/16+em;
    }

    a, span {
      color: $dark-blue;
      font-weight: bold;
      text-transform: uppercase;
    }

    a {
      transition: .3s;

      &:hover {
        color: $dark-blue-hover;
      }

      &.active {
        color: $red;
      }
    }
  }
  
  .menu {
    ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      @media screen and (max-width: $tablet) {
        flex-direction: column;
      }
      
      li {
        position: relative;

        @media screen and (max-width: $tablet) {
          text-align: center;
        }

        &:not(:last-child) {
          @media screen and (min-width: $tablet + 1) {
            margin-right: 48/16+em;
          }

          @media screen and (max-width: $tablet) {
            margin-bottom: 1em;
          }
        }

        &.active {
          a {
            color: $dark-blue;
          }
        }

        &.dropdown {
          &>a {
            span {
              &:after {
                content: "\e901";
                font-family: 'icomoon';
                font-size: .75em;
                margin-left: .5em;
                display: inline-block;
                transition: .3s transform;
              }
            }

            &.opened {
              span {
                color: $blue;

                &:after {
                  transform: rotate(180deg);
                }
              }
            }

            &:hover {
              span {
                transform: none;
              }
            }
          }

          &:hover {
            @media screen and (min-width: $tablet + 1) {
              &>a {
                span {
                  color: $blue;

                  &:after {
                    transform: rotate(180deg);
                  }
                }
              }

              .dropdown-menu {
                display: flex;
              }
            }
          }
        }

        .dropdown-menu {
          z-index: 1;
          top: 100%;
          background: #fff;
          flex-direction: column;
          padding: .75em 1.5em;
          box-shadow: 0 4px 10px rgba(0,0,0,.2);
          align-items: flex-start;
          opacity: 0;
          transform: translateY(-1em);
          display: none;
          animation: fadeDown .5s forwards;

          @media screen and (min-width: $tablet + 1) {
            position: absolute;
            margin-left: -1.5em;
            left: 0;
          }
          
          @keyframes fadeDown {
            100% {
              opacity: 1;
              transform: translateY(0);
            }
          }

          li {
            margin-right: 0;
          }
        }
      }
      
      a {
        font-size: 20/16+em;
        font-weight: 500;
        padding: .5em 0;
        transition: .3s;
        white-space: nowrap;
        display: inline-block;
        position: relative;
        overflow: hidden;

        span {
          display: inline-block;
          transition: .3s;

          &:before {
            content: attr(data-title);
            position: absolute;
            right: 100%;
            color: $blue;
            width: 100%;
          }
        }

        @media screen and (max-width: $laptop-s) {
          font-size: 1.125em;
        }

        &:hover {
          span {
            transform: translateX(100%);
          }
        }
      }
    }

    &--kk {
      ul li {
        &:not(:last-child) {
          @media screen and (min-width: $tablet + 1) {
            margin-right: 2em;
          }
        }

        a {
          font-size: 18/16+em;
        }
      }
    }
  }
}