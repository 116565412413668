.map {
  width: 924/16+em;
  max-width: 100%;
  margin: 4em auto;

  svg {
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.25));
  }

  .land {
    fill: #A3D9F7;
    fill-opacity: 1;
    stroke: #fff;
    stroke-opacity: 1;
    stroke-width: 1.5;
    transition: .3s;
  }

  #regions .area-pin {
    font-family: 'icomoon';
    transform: translate(0em,437.41px);
    cursor: pointer;
    font-size: 1.7em;
    fill: $red;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  #regions .area-title {
    transform: translate(0em,437.41px);
    cursor: pointer;
    text-anchor: middle;
    font-size: .75em;
    fill: $dark-blue;
  }

  a:hover {
    .land {
      fill: #1AA7E0;
    }
  }
}