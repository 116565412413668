.equipment {
  display: flex;
  justify-content: space-around;
  padding: 0 8em;

  @media screen and (max-width: $laptop-m) {
    flex-direction: column;
    align-items: center;
  }

  &>div {
    &:first-child {
      width: 324/16+rem;
    }

    &:last-child {
      width: 298/16+rem;
    }
  }

  &:hover {
    svg a {
      opacity: .5;

      &:hover {
        opacity: 1;
      }
    }

    .equipment-title:hover+svg a {
      opacity: 1;
    }
  }

  &__base, &__jersey {
    position: relative;

    &>div {
      position: absolute;

      svg {
        width: 100%;
        height: 100%;

        a {
          transition: .3s;
        }
      }

      .equipment-title {
        position: absolute;
        font-weight: 600;
        font-size: 18/16+em;
        display: flex;
        align-items: center;

        @media screen and (max-width: $tablet) {
          display: none;
        }

        &:before {
          content: '';
          width: 40/18+em;
          border-top: 1px solid #ccc;
          margin-right: 10/18+em;
        }

        &.reversed {
          flex-direction: row-reverse;

          &:before {
            margin-right: 0;
            margin-left: 10/18+em;
          }
        }
      }
    }
  }

  &__base {
    padding-bottom: 621/324*100%;

    .helmet {
      left: 73.29/324*100%;
      top: 0;
      width: 65/324*100%;

      .equipment-title {
        left: 115%;
        top: 31%;
      }
    }

    .bib {
      left: 13.22/324*100%;
      top: 100.57/621*100%;
      width: 185/324*100%;

      .equipment-title {
        left: 105%;
        top: 21%;
      }
    }

    .elbow-pads {
      left: 0.31/324*100%;
      top: 203.23/621*100%;
      width: 211/324*100%;

      .equipment-title {
        right: 104%;
        top: 46%;
      }
    }

    .gloves {
      left: 0;
      top: 279.15/621*100%;
      width: 206/324*100%;

      .equipment-title {
        right: 106%;
        top: 41%;
      }
    }

    .shorts {
      left: 51.66/324*100%;
      top: 244.48/621*100%;
      width: 108/324*100%;

      .equipment-title {
        right: 114%;
        top: 77%;
      }
    }

    .knee-pads {
      left: 56.82/324*100%;
      top: 409.04/621*100%;
      width: 98/324*100%;

      .equipment-title {
        right: 111%;
        top: 41%;
      }
    }

    .skates {
      left: 31.84/324*100%;
      top: 531.99/621*100%;
      width: 158/324*100%;

      .equipment-title {
        right: 108%;
        top: 41%;
      }
    }

    .hockey-stick {
      left: 245.89/324*100%;
      top: 200.73/621*100%;
      width: 80/324*100%;

      .equipment-title {
        left: 30%;
        top: 50%;
      }
    }
  }

  &__jersey {
    margin-top: 6rem;
    padding-bottom: 152/298*100%;

    @media screen and (max-width: $tablet) {
      margin-top: 3rem;
    }

    .jersey {
      left: 0;
      top: 0;
      width: 152/152*100%;

      .equipment-title {
        left: 95%;
        top: 15%;
      }
    }
  }
}