//Свойство Object-fit
@mixin object-fit($fit: fill, $position: null){
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin col($col, $space: null) {
  display: flex;
  flex-wrap: wrap;

  &>* {
    width: 100%/$col;
  }

  @if ($space) {
    margin: -$space;

    &>* {
      padding: $space;
    }
  }
}

@mixin col-width($a, $b) {
  width: calc(100%*#{$b}/#{$a});
}