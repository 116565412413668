@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-Medium.eot');
  src: url('/assets/fonts/exo2/Exo2-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-Medium.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-Medium.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-Medium.svg#Exo2-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-Italic.eot');
  src: url('/assets/fonts/exo2/Exo2-Italic.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-Italic.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-Italic.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-Italic.svg#Exo2-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-Bold.eot');
  src: url('/assets/fonts/exo2/Exo2-Bold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-Bold.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-Bold.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-Bold.svg#Exo2-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-ExtraBold.eot');
  src: url('/assets/fonts/exo2/Exo2-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-ExtraBold.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-ExtraBold.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-ExtraBold.svg#Exo2-ExtraBold') format('svg');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-Regular.eot');
  src: url('/assets/fonts/exo2/Exo2-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-Regular.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-Regular.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-Regular.svg#Exo2-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-Black.eot');
  src: url('/assets/fonts/exo2/Exo2-Black.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-Black.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-Black.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-Black.svg#Exo2-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-SemiBold.eot');
  src: url('/assets/fonts/exo2/Exo2-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-SemiBold.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-SemiBold.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-SemiBold.svg#Exo2-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2';
  src: url('/assets/fonts/exo2/Exo2-Light.eot');
  src: url('/assets/fonts/exo2/Exo2-Light.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/exo2/Exo2-Light.woff') format('woff'),
  url('/assets/fonts/exo2/Exo2-Light.ttf') format('truetype'),
  url('/assets/fonts/exo2/Exo2-Light.svg#Exo2-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icoMoon/icomoon.eot?gtigsj');
  src:  url('/assets/fonts/icoMoon/icomoon.eot?gtigsj#iefix') format('embedded-opentype'),
  url('/assets/fonts/icoMoon/icomoon.ttf?gtigsj') format('truetype'),
  url('/assets/fonts/icoMoon/icomoon.woff?gtigsj') format('woff'),
  url('/assets/fonts/icoMoon/icomoon.svg?gtigsj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-air-hockey:before {
  content: "\e90a";
}
.icon-search:before {
  content: "\e909";
}
.icon-pin:before {
  content: "\e908";
}
.icon-chevron-right:before {
  content: "\e900";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-chevron-left:before {
  content: "\e902";
}
.icon-chevron-up:before {
  content: "\e903";
}
.icon-instagram:before {
  content: "\e904";
}
.icon-play:before {
  content: "\e905";
}
.icon-envelope:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e907";
}
