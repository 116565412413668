section {
  &:not(:last-child) {
    margin-bottom: 80/16+em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 4rem;
    }
  }

  &.field-bg {
    background: url("/assets/img/field-outline-1.svg"), #e4f4fd;
    background-size: 100%;
    margin: -5em 0;
    padding: 5em 0;
  }
}