.breadcrumbs {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  color: $text-gray;
  display: flex;
  flex-wrap: wrap;

  li {
    &:not(:last-child) {
      &:after {
        content: '-';
        margin: 0 5/16+em;
      }
    }
  }

  a {
    transition: .3s;

    &:hover {
      color: $dark-blue;
    }
  }

  &:not(:last-child) {
    margin-bottom: 20/16+rem;

    @media screen and (max-width: $tablet) {
      margin-bottom: 1rem;
    }
  }
}