.card {
  background: $extra-light-blue;
  box-shadow: $box-shadow;
  padding: 1.5rem;
  border-radius: 10px;
  display: flex !important;
  flex-direction: column;
  transition: .3s box-shadow;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: $tablet) {
    padding: $padding-inner-mobile;
  }

  &:hover {
    box-shadow: $box-shadow-hover;

    .card__img {
      img {
        transform: scale(1.1);
      }
    }

    .btn {
      background: $blue-hover;

      &:after {
        color: $blue-hover;
      }

      &--red {
        background: $red-hover;

        &:after {
          color: $red-hover;
        }
      }
    }
  }

  &__img {
    position: relative;
    padding-bottom: 215/280*100%;
    border-radius: 5px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: 15/16+rem;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include object-fit(cover);
      transition: .3s;
      //transform-origin: left;
    }
  }

  &__date {
    font-size: 14/16+em;
    color: $text-gray;
  }

  &__title {
    margin-top: 0;
    font-weight: 600;
    font-size: 18/16+em;
    color: $dark-blue;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 15/16+rem;
    }
  }

  &__annotation {
    font-size: 14/16+em;

    &:not(:last-child) {
      margin-bottom: 15/16+rem;
    }
  }

  hr {
    margin-top: auto;
    border: none;
    align-self: flex-start;
    border-bottom: 2px solid #FFFFFF;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 15/16+rem;
    }
  }

  .btn {
    align-self: flex-start;
    margin-top: auto;
  }

  &__type2 {
    .card__img {
      margin: -1.5rem -1.5rem 15/16+rem;
      border-radius: 0;

      @media screen and (max-width: $tablet) {
        margin: (-$padding-inner-mobile) (-$padding-inner-mobile) 15/16+rem;
      }
    }

    .video-cover {
      padding-bottom: 56%;
    }
  }
}