.article {
  &-sidebar {
    .article__image {
      width: 100%;
      float: none;
      margin: 0;
    }

    &>* {
      &:not(:last-child) {
        margin-bottom: 20/16+rem;
      }
    }
  }

  &__date {
    color: $text-gray;
    font-size: 14/16+em;
  }

  &__image {
    width: 340/16+em;
    float: left;
    margin-right: 30/16+em;
    margin-bottom: 30/16+em;
    border-radius: 20px;

    &--school {
      padding: 10% 20%;
      @include object-fit(contain);
    }

    @media screen and (max-width: $laptop-s) {
      width: 15rem;
    }

    @media screen and (max-width: $tablet) {
      width: 100%;
      margin-right: 0;
    }
  }
}