.form-modal {
  width: 460/16+rem;
  max-width: 100%;
}

.fancybox-slide:not(.fancybox-slide--image) .fancybox-content {
  border-radius: $border-radius;
  padding: 80/16+em 50/16+em;

  @media screen and (max-width: $tablet) {
    padding: 80/16+em 30/16+em;
    border-radius: $border-radius-mobile;
  }

  .title-primary {
    margin-bottom: 35/16+rem;
  }
}

button.fancybox-button.fancybox-close-small {
  padding: 0;
  width: 45/16+rem;
  height: 45/16+rem;
  top: 20/16+rem;
  right: 20/16+rem;
  opacity: 0.2;
  transition: .3s;

  &:hover {
    opacity: 1;
  }
}