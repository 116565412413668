.mobile-dropdown {
  //margin-bottom: $padding*2;
  position: relative;

  @media screen and (max-width: $tablet) {
    //margin-bottom: $padding-mobile*2;
  }

  &.opened {
    .mobile-dropdown__desc {
      display: block;
    }
  }

  &__title {
    color: $text-gray;
    font-weight: bold;
    padding: .5em 0;
    display: inline-flex;
    align-items: center;

    &:after {
      content: "\e901";
      font-family: 'icomoon';
      margin-left: .5rem;
      font-size: .75em;
    }

    @media screen and (min-width: $tablet + 1) {
      display: none;
    }
  }

  &__desc {
    @media screen and (max-width: $tablet) {
      display: none;
      border-radius: 4px;
      position: absolute;
      max-width: 100%;
      top: 100%;
      z-index: 1;
      box-shadow: $box-shadow;
      animation: fade forwards .3s;
      background: $extra-light-blue;
      padding: 10/16+rem;

      &>div {
        background: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        min-width: 7rem;

        a {
          font-size: 1em;
          padding: 5/16+rem;

          &:not(:last-child) {
            margin-bottom: 10/16+rem;
          }
        }
      }

      @keyframes fade {
        0% {
          opacity: 0;
          transform: translateY(-.5em);
        }
        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }
}