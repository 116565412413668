.field-plan {
  background: url("/assets/img/field/field-outline.svg") no-repeat center center, rgba(0, 158, 224, 0.75);
  background-size: 90%;
  padding: 3em 0;

  .title-primary {
    color: #fff;
    text-shadow: 0 0 5px rgba(0,0,0,0.4);
  }

  a {
    img {
      transition: .3s;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }

  .row {
    @media screen and (max-width: $laptop-s) {
      &>[class^="col"] {
        &:nth-of-type(1) {
          order: 2;
        }

        &:nth-of-type(3) {
          order: 3;
        }
      }
    }
  }
}

.field-item {
  display: table;
  margin: 0 auto 3rem;

  &:hover {
    img {
      background: #fff;
    }
  }

  img {
    width: 12em;
    border-radius: 50%;
    transition: .3s background;
  }
}

.field-item-main {
  display: table;
  margin: 5rem auto;

  @media screen and (max-width: $tablet) {
    margin: 0 auto 3rem;
  }

  img, svg {
    width: 25em;
    height: auto;
    max-width: 100%;
  }

  a {
    opacity: 0;
    transition: .3s;

    &:hover {
      opacity: .8;
    }
  }
}