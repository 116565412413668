.kit-plan {
  //padding: 3em 0;

  a {
    img {
      transition: .3s;
    }

    &:hover {
      img {
        transform: scale(1.05);
      }

      span {
        color: $dark-blue;
      }
    }
  }

  .row {
    @media screen and (max-width: $laptop-s) {
      &>[class^="col"] {
        &:nth-of-type(1) {
          order: 2;
        }

        &:nth-of-type(3) {
          order: 3;
        }
      }
    }
  }
}

.kit-item {
  display: table;
  margin: 0 auto 3rem;
  text-align: center;

  img {
    width: 12em;
    border-radius: 50%;
  }

  &:hover {
    img {
      background: #fff;
    }
  }

  span {
    font-size: 1.25em;
    font-weight: 500;
    transition: .3s;
    display: block;

    @media screen and (max-width: $tablet) {
      min-height: 48/20+em;
    }
  }
}

.kit-item-main {
  display: table;
  margin: 2.5rem auto;

  @media screen and (max-width: $tablet) {
    margin-top: 0;
  }

  a {
    transition: .3s opacity;
    opacity: 0;

    &:hover {
      opacity: .4;
    }
  }

  img, svg {
    max-width: 100%;
    width: 17em;
    height: auto;

    @media screen and (max-width: $tablet) {
      width: 12em;
    }
  }
}