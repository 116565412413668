.input-group {
  &:not(:last-child) {
    margin-bottom: 20/16+em;
  }
}

.input-regular {
  background: $extra-light-blue;
  border: 2px solid $extra-light-blue;
  border-radius: 4px;
  font-size: 16/16+em;
  padding-left: 24/16+em;
  padding-right: 24/16+em;
  height: 50/16+em;
  outline: none !important;
  width: 100%;
  transition: .3s;
  font-family: inherit;
  -webkit-appearance: none;

  &:focus {
    border-color: $blue;
  }
}

textarea.input-regular {
  width: 100% !important;
  padding-top: 15/16+em;
  padding-bottom: 15/16+em;
  height: 6em;
}

select.input-regular {
  -webkit-appearance: unset;
}