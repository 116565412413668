.blue-title {
  background: $dark-blue;
  margin-top: -60/16+em;
  padding: 60/16+em 0;

  @media screen and (max-width: $tablet) {
    margin-top: -2em;
    padding: 2.4em 0;
  }

  .title-primary {
    color: #fff;
  }
}