.sidebar-nav {
  padding: 20/16+em;
  background: $extra-light-blue;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    color: $text-gray;
    font-weight: 600;
    font-size: 18/16+em;
    padding: 5/16+rem;
    transition: .3s;

    &:hover {
      color: #666;
    }

    &.active {
      color: $dark-blue;
    }

    &:not(:last-child) {
      margin-bottom: 10/16+rem;
    }
  }
}