.carousel-regular {
  position: relative;

  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 100%;
    top: 0;
    height: calc(100% + 15px);
    width: calc((100vw - 1060px)/2);
    background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,.85));
  }

  &:before {
    left: unset;
    right: 100%;
    transform: rotate(180deg);
  }

  .slick-arrow {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: calc(50rem/16 - 1em);
    font-size: 20/16+em;
    padding: .5em;
    transition: .3s;
    color: $dark-blue;
    cursor: pointer;

    @media screen and (max-width: $tablet) {
      margin-bottom: calc(30rem/16 - 1em);
    }

    &:hover {
      color: $red;
    }

    &.icon-chevron-left {
      right: 40/16+rem;
    }
  }

  .slick-list {
    margin: -$padding;
    overflow: visible;
  }

  .slick-track {
    display: flex !important;
    margin-left: 0;

    .slick-slide {
      float: none;
      height: unset;
    }

    .slick-slide>div, .card {
      height: 100%;
    }
  }

  .slick-slide {
    outline: none !important;
    padding: $padding;
  }
}