.container {
  max-width: calc(1060px + 4em);
  margin: 0 auto;
  padding: 0 2em;
  width: 100%;

  @media screen and (max-width: $laptop-m) {
    max-width: calc(992px + 4em);
  }

  @media screen and (max-width: $laptop-s) {
    max-width: calc(768px + 4em);
  }

  @media screen and (max-width: $tablet) {
    max-width: calc(480px + 4em);
  }

  @media screen and (max-width: 340px) {
    padding: 0 1em;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$padding;
  margin-right: -$padding;

  &--multiline {
    margin: -$padding;

    &>[class^='col-'] {
      padding: $padding;
    }
  }

  &>[class^='col-'] {
    padding-left: $padding;
    padding-right: $padding;
  }
}

[class^='col-'] {
  flex-shrink: 0;
  width: 100%;
}

.col-auto {
  width: auto;
}

.col-xs-1 {width: 100%/12*1;}
.col-xs-2 {width: 100%/12*2;}
.col-xs-3 {width: 100%/12*3;}
.col-xs-4 {width: 100%/12*4;}
.col-xs-5 {width: 100%/12*5;}
.col-xs-6 {width: 100%/12*6;}
.col-xs-7 {width: 100%/12*7;}
.col-xs-8 {width: 100%/12*8;}
.col-xs-9 {width: 100%/12*9;}
.col-xs-10 {width: 100%/12*10;}
.col-xs-11 {width: 100%/12*11;}
.col-xs-12 {width: 100%/12*12;}

@media screen and (min-width: $tablet + 1) {
  .col-sm-1 {width: 100%/12*1;}
  .col-sm-2 {width: 100%/12*2;}
  .col-sm-3 {width: 100%/12*3;}
  .col-sm-4 {width: 100%/12*4;}
  .col-sm-5 {width: 100%/12*5;}
  .col-sm-6 {width: 100%/12*6;}
  .col-sm-7 {width: 100%/12*7;}
  .col-sm-8 {width: 100%/12*8;}
  .col-sm-9 {width: 100%/12*9;}
  .col-sm-10 {width: 100%/12*10;}
  .col-sm-11 {width: 100%/12*11;}
  .col-sm-12 {width: 100%/12*12;}
}

@media screen and (min-width: $laptop-s + 1) {
  .col-md-1 {width: 100%/12*1;}
  .col-md-2 {width: 100%/12*2;}
  .col-md-3 {width: 100%/12*3;}
  .col-md-4 {width: 100%/12*4;}
  .col-md-5 {width: 100%/12*5;}
  .col-md-6 {width: 100%/12*6;}
  .col-md-7 {width: 100%/12*7;}
  .col-md-8 {width: 100%/12*8;}
  .col-md-9 {width: 100%/12*9;}
  .col-md-10 {width: 100%/12*10;}
  .col-md-11 {width: 100%/12*11;}
  .col-md-12 {width: 100%/12*12;}
}

@media screen and (min-width: $laptop-m + 1) {
  .col-lg-1 {width: 100%/12*1;}
  .col-lg-2 {width: 100%/12*2;}
  .col-lg-3 {width: 100%/12*3;}
  .col-lg-4 {width: 100%/12*4;}
  .col-lg-5 {width: 100%/12*5;}
  .col-lg-6 {width: 100%/12*6;}
  .col-lg-7 {width: 100%/12*7;}
  .col-lg-8 {width: 100%/12*8;}
  .col-lg-9 {width: 100%/12*9;}
  .col-lg-10 {width: 100%/12*10;}
  .col-lg-11 {width: 100%/12*11;}
  .col-lg-12 {width: 100%/12*12;}
}



.hidden-xs {
  @media screen and (max-width: $tablet) {
    display: none !important;
  }
}

.hidden-sm {
  @media screen and (min-width: $tablet + 1) and (max-width: $laptop-s) {
    display: none !important;
  }
}

.hidden-md {
  @media screen and (min-width: $laptop-s + 1) and (max-width: $laptop-m) {
    display: none !important;
  }
}

.hidden-lg {
  @media screen and (min-width: $laptop-m + 1) {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mob-100 {
  @media screen and (max-width: $mobile-l) {
    width: 100%;
  }
}