.btn {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  padding: 0 25/16+em;
  height: 40/16+em;
  background: $blue;
  color: #fff;
  transition: .3s;
  border-radius: 20/16+em;
  border: none;
  cursor: pointer;
  min-width: 150/16+rem;
  justify-content: center;
  font-family: inherit;

  &:hover {
    background: $blue-hover;

    &:after {
      color: $blue-hover;
    }
  }

  &>* {
    &:not(:last-child) {
      margin-right: 10/16+em;
    }
  }

  &--arrow {
    position: relative;
    padding-right: 60/16+em;

    &:after {
      content: "\e900";
      font-family: 'icomoon';
      font-size: 12/16+em;
      background: #fff;
      width: 32/12+em;
      height: 32/12+em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      right: 4/12+em;
      padding-left: 0.15em;
      color: $blue;
    }
  }

  &--red {
    background: $red;

    &:hover {
      background: $red-hover;

      &:after {
        color: $red-hover;
      }
    }

    &:after {
      color: $red;
    }
  }

  &--dark {
    background: $dark-blue;

    &:hover {
      background: $dark-blue-hover;

      &:after {
        color: $dark-blue-hover;
      }
    }

    &:after {
      color: $dark-blue;
    }
  }
}

.ghost-btn {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  padding: 0 25/16+em;
  height: 40/16+em;
  color: $blue;
  transition: .3s;
  border-radius: 20/16+em;
  border: 2px solid;

  &:hover {
    color: $blue-hover;
    background: $extra-light-blue;
  }

  &>* {
    &:not(:last-child) {
      margin-right: 10/16+em;
    }
  }
}

.title-primary {
  font-weight: 800;
  font-size: 32/16+em;
  color: $dark-blue;
  margin-top: 0;
  margin-bottom: 0;

  @media screen and (max-width: $tablet) {
    font-size: 1.75em;
  }

  &:not(:last-child) {
    margin-bottom: 30/16+rem;
  }

  img {
    width: 32/32+em;
    height: 32/32+em;
    display: inline-block;
    vertical-align: middle;
    transform: translateY(-.1em);
    margin-right: .5rem;
  }
}

.title-block {
  &:not(:last-child) {
    margin-bottom: 50/16+em;

    @media screen and (max-width: $tablet) {
      margin-bottom: 30/16+em;
    }
  }
  
  &>* {
    &:not(:last-child) {
      margin-bottom: 20/16+rem;

      @media screen and (max-width: $tablet) {
        margin-bottom: 1rem;
      }
    }
  }

  .gray-link {
    padding-right: 5rem;
    display: table;
  }
}

.subtitle {
  color: #999;
}

.gray-link {
  font-size: 18/16+em;
  text-decoration: underline;
  color: $text-gray;

  &:hover {
    text-decoration: none;
  }
}

.margin-50 {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 50/16+rem;
  }
}