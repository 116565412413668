.main {
  padding: 60/16+em 0;

  @media screen and (max-width: $tablet) {
    padding: 2rem 0;
  }
}

.main-wrapper {
  position: relative;

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
  }
}