.roles {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50/16+em;

  @media screen and (max-width: $tablet) {
    flex-direction: column;
    align-items: center;
  }

  img {
    height: 13em;
  }
}

.roles-item {
  position: relative;
  transition: .3s;

  @media screen and (max-width: $laptop-m) {
    font-size: .75em;
  }

  @media screen and (max-width: $tablet) {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &:hover {
    transform: scale(1.05);
  }

  span {
    position: absolute;
    top: 20%;
    left: 100%;
    font-size: 1.5em;
    font-weight: 500;
    margin-left: -3em;
  }
}