.about {
  .about-item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 100/16+rem;

      @media screen and (max-width: $tablet) {
        margin-bottom: 30/16+em;
      }
    }

    h3 {
      font-size: 3.6em;
      font-weight: 900;
      margin-top: 0;
      margin-bottom: 2rem;
      line-height: 1;

      @media screen and (max-width: $laptop-m) {
        font-size: 2.5em;
      }
      
      img {
        @media screen and (max-width: $tablet) {
          display: none;
        }
      }
    }

    .video-cover {
      padding-bottom: 279/437*100%;

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    .row {
      margin: -25/16+rem;
      align-items: flex-end;

      @media screen and (max-width: $tablet) {
        margin: -15/16+rem;
      }

      &>[class^="col-"] {
        padding: 25/16+rem;

        @media screen and (max-width: $tablet) {
          padding: 15/16+rem;
        }
      }
    }

    &:nth-of-type(even) {
      .row {
        @media screen and (min-width: $tablet + 1) {
          flex-direction: row-reverse;
        }
      }
    }
  }
}