.rink-bg {
  position: relative;
  z-index: -5;

  img {
    height: 2801.14/16+em;
    width: 1418.15/16+em;
    max-width: unset;
    opacity: 0.05;
    transform: rotate(-15deg) translateX(-42%) translateY(-5%);
    position: absolute;
    left: 50%;
  }
}