.privilege {
  display: inline-flex;
  align-items: flex-end;
  text-align: left;

  &__img  {
    width: 10em;
    flex-shrink: 0;
  }

  &__text {
    display: flex;
    flex-direction: column;
    color: #2D3447;
    margin-left: -2em;
    margin-bottom: 0.5em;
  }

  &__count {
    font-size: 3em;
    font-weight: bold;
  }

  &__title {
    font-size: 1.25em;
    margin-left: 1em;
    margin-top: -0.3em;
    width: 6em;
  }
}