.plain-text {
  &:not(:last-child) {
    margin-bottom: 50/16+rem;
  }

  &__18 {
    @media screen and (min-width: $tablet + 1) {
      font-size: 18/16+em;
    }
  }

  &__20 {
    @media screen and (min-width: $tablet + 1) {
      font-size: 20/16+em;
    }
  }

  &__22 {
    @media screen and (min-width: $tablet + 1) {
      font-size: 22/16+em;
    }
  }

  &__24 {
    @media screen and (min-width: $tablet + 1) {
      font-size: 24/16+em;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 24/16+em;
  }

  p, ul, ol {
     &:last-child {
       margin-bottom: 0;
     }

     a {
       color: $dark-blue;
       text-decoration: underline;

       &:hover {
         color: $dark-blue-hover;
       }
     }
   }

  img {
    max-width: 100%;
    height: auto;

    @media screen and (max-width: $mobile-l) {
      width: 100% !important;
      height: auto !important;
    }
  }

  blockquote {
    margin: 2rem 0;
    background: $extra-light-blue;
    padding: 30/16+em;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;

    td, th {
      padding: .5em;
      border: 1px solid $border-color;

      &:first-child {
        font-weight: bold;
      }
    }

    th {
      text-align: left;
    }
  }

  a {
    color: $blue;

    &:hover {
      color: $blue-hover;
    }
  }

  span {
    font-family: inherit !important;
    font-size: inherit !important;
  }
}