.footer {
  background: $dark-blue;
  color: #fff;
  padding: 30/16+em 0;
  position: relative;
  z-index: 2;

  p {
    font-size: 18/16+em;
    line-height: 1.37;

    @media screen and (max-width: $tablet) {
      font-size: 1em;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      transition: .3s;

      &:hover {
        opacity: .75;
      }
    }
  }
  
  .row {
    @media screen and (max-width: $tablet) {
      flex-direction: column-reverse;
    }
  }
}