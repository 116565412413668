.categories-list {
  a {
    color: $text-gray;
    font-weight: 600;
    font-size: 24/16+em;
    transition: .3s;

    &:hover {
      color: #666;
    }

    &.active {
      color: $dark-blue;
    }

    &:not(:last-child) {
      margin-right: 20/16+rem;
    }
  }
}