.school {
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  padding: 5em 2em;

  @media screen and (max-width: $tablet) {
    padding: 3em 1.5em 1.5em;
  }

  &:not(:last-child) {
    margin-bottom: 50/16+em;
  }

  &__img {
    img {
      width: 180px;
      display: block;
      margin: 0 auto;
    }
  }

  &__title {
    font-size: 1.5em;
    margin-top: 0;
  }

  &__text {
    font-size: 1.125em;

    span {
      font-family: inherit !important;
      font-size: inherit !important;
    }

    a {
      color: $blue;

      &:hover {
        color: $blue-hover;
      }
    }
  }
}