.slogan {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  img {
    width: 8em;
  }

  div {
    margin: 0 2em;

    @media screen and (max-width: $tablet) {
      width: 100%;
      margin: 0;
    }
  }

  p {
    color: $dark-blue;
    font-size: 1.5em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: $tablet) {
    flex-wrap: wrap;

    img {
      width: 9rem;
      max-width: 50%;
      padding: 1rem;
      order: 2;
    }
  }
}