.video-cover {
  position: relative;
  cursor: pointer;
  display: block;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .2;
    z-index: 2;
    transition: .3s;
  }

  &__btn {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100/16+em;
    height: 100/16+em;
    border-radius: 50%;
    background: rgba(255,255,255,.75);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $tablet) {
      font-size: .75em;
    }

    i {
      font-size: 40/16+em;
      color: $red;
      border-radius: 50%;
      padding: 20/40+em;
      background: rgba(255,255,255,.75);
      transition: .3s;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50/16+em;
    height: 50/16+em;
    background: url("/assets/img/play.svg");
    background-size: 100% 100%;
    z-index: 2;
    opacity: 0.7;
    transition: .3s;
  }

  &:hover {
    &:before {
      opacity: .4;
    }

    .video-cover__btn {
      i {
        background: #fff;
      }
    }

    .video-cover__play {
      opacity: 1;
    }
  }
}