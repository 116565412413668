.spoilers {
  .spoiler {
    border-radius: 10px;
    border: 1px solid $border-color;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,.2);
    background: #fff;
    transition: background .3s;

    &:not(:last-child) {
      margin-bottom: 50/16+em;

      @media screen and (max-width: $tablet) {
        margin-bottom: 30/16+em;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: .3s;
      padding: 40/16+em 50/16+em;

      @media screen and (max-width: $tablet) {
        padding: $padding-inner-mobile;
      }

      span {
        flex-grow: 1;
        font-size: 24/16+em;

        @media screen and (max-width: $tablet) {
          font-size: 1.125em;
          font-weight: 500;
        }
      }

      &:after {
        content: "\e901";
        font-family: 'icomoon';
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
        flex-shrink: 0;
        font-size: 1em;
        color: #fff;
        background: $red;
        width: 54/16+em;
        height: 54/16+em;
        border-radius: 50%;
        margin-left: 1em;

        @media screen and (max-width: $tablet) {
          margin-left: 1rem;
          font-size: 0.6em;
        }
      }

      &:hover {
        background: $extra-light-blue;

        &:after {
          background: $red-hover;
        }
      }
    }

    &__desc {
      display: none;
      padding: 0 50/16+em 40/16+em;
      background: $extra-light-blue;

      @media screen and (max-width: $tablet) {
        padding: 0 $padding-inner-mobile $padding-inner-mobile;
      }

      &>div {
        animation: fade 1s forwards;
        opacity: 0;

        @keyframes fade {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      }
      
      .plain-text {
        @media screen and (max-width: $tablet) {
          font-size: 1em !important;
        }
      }
    }

    &.active {
      .spoiler__title {
        background: $extra-light-blue;

        &:after {
          transform: rotate(180deg);
        }
      }

      .spoiler__desc {
        display: block;

        &>div {
          opacity: 1;
        }
      }
    }
  }
}
