.scroll-up {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: $red;
  color: #fff;
  border-radius: 50%;
  font-size: 18/16+em;
  padding: 15/18+em;
  cursor: pointer;
  z-index: 9;
  transition: .3s background-color;
  box-shadow: $box-shadow;
  display: none;

  &:hover {
    background-color: $red-hover;
  }
}