.gallery-item {
  display: block;
  position: relative;
  padding-bottom: 215/280*100%;
  border-radius: 20px;
  overflow: hidden;

  @media screen and (max-width: $tablet) {
    border-radius: 10px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include object-fit(cover);
    transition: .3s;
    //transform-origin: left;
  }
}