.menu-btn-wrapper {
  position: fixed;
  padding: 10/16+em;
  top: 2rem;
  right: 2rem;
  background: $extra-light-blue;
  box-shadow: $box-shadow;
  border-radius: 4px;
  z-index: 999;
  transition: .3s box-shadow;

  @media screen and (min-width: $tablet + 1) {
    top: 3.5rem;
  }

  @media screen and (min-width: $laptop-s + 1) {
    display: none;
  }

  .menu-btn {
    position: relative;
    width: 30px;
    height: 23px;
    transition: .5s ease-in-out;
    cursor: pointer;
    flex-shrink: 0;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $dark-blue;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: .25s ease-in-out;
      border-radius: 1.5px;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 10px;
      }

      &:nth-child(4) {
        top: 20px;
      }
    }
  }

  &.opened {
    box-shadow: none;

    .menu-btn span {
      &:nth-child(1) {
        top: 10px;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 10px;
        width: 0;
        left: 50%;
      }
    }
  }
}